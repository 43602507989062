<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { pluralize } from '/~/utils/format/string'
import { formatNumber } from '/~/utils/format/numeric'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-tokens',
  components: {
    BaseIcon,
  },
  props: {
    color: {
      type: String,
      default: '',
      validator: (v) => !v || /light|success|gold|info/.test(v),
    },
    nominal: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    uppercase: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: 'points/token',
    },
  },
  setup() {
    const { currency } = useRecProfile()

    return {
      currency,
      pluralize,
      formatNumber,
    }
  },
  computed: {
    clickable() {
      return Boolean(this.$listeners.click)
    },
  },
}
</script>

<template>
  <div
    class="relative flex min-h-6 items-center justify-center bg-current"
    :class="{
      'text-fg-success': color === 'success',
      'text-eonx-neutral-600': color === 'light',
      'text-amber-400': color === 'gold' || (!color && currency !== 'points'),
      'text-fg-info': color === 'info' || (!color && currency === 'points'),
    }"
  >
    <div class="flex items-center justify-center text-white">
      <slot name="badge">
        <base-icon class="mr-2.5 block shrink-0" :svg="icon" :size="16" />
        <span class="text-sm font-semibold leading-4">
          {{ formatNumber(nominal) }}
          {{
            pluralize(nominal, currency.slice(0, -1)).replace(
              'points',
              'Points'
            )
          }}
          {{ label }}
        </span>
      </slot>
    </div>
  </div>
</template>
